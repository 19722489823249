/**
 * 示例-自定义组件-上传
 * luxinwen
 * 2023-01
 */
<template>
  <div class="page">
    <div class="page-main">
      <div class="page-main-content">
        <div class="page-main-content-thead">sp-upload 上传</div>
        <div class="page-main-content-subhead">示例</div>
        <Card class="page-main-content-item">
          <sp-upload v-model="value"></sp-upload>
          <Divider orientation="left" plain>基本用法</Divider>
          <pre>&lt;sp-upload v-model="value"&gt;&lt;/sp-upload&gt;</pre>
          <p>最基本用法，点击上传，一次选择一个文件。</p>
          <p>绑定值可为字符串或数组，为数组时格式如下（必须有url字段）：</p>
          <pre>[
  {
    url: 'https://tenfei04.cfp.cn/creative/vcg/800/new/VCG211290936562.jpg'
  },
  {
    url: 'https://tenfei04.cfp.cn/creative/vcg/800/new/VCG211290936562.jpg'
  }
]</pre>
        </Card>
        <Card class="page-main-content-item">
          <sp-upload v-model="value" disabled></sp-upload>
          <Divider orientation="left" plain>禁用状态</Divider>
          <pre>&lt;sp-upload v-model="value" disabled&gt;&lt;/sp-upload&gt;</pre>
          <p>设置属性 <span class="text-red">disabled</span>，可以禁止上传，此时只可预览图片。</p>
        </Card>
        <Card class="page-main-content-item">
          <sp-upload v-model="valueList" multiple></sp-upload>
          <Divider orientation="left" plain>多选上传</Divider>
          <pre>&lt;sp-upload v-model="valueList" multiple&gt;&lt;/sp-upload&gt;</pre>
          <p>设置属性 <span class="text-red">multiple</span>，可以选择多个文件，此时绑定值必须为数组格式。</p>
        </Card>
        <Card class="page-main-content-item">
          <sp-upload v-model="value" :img-width="200" :img-height="[100, 1000]"></sp-upload>
          <Divider orientation="left" plain>限制图片尺寸</Divider>
          <pre>&lt;sp-upload v-model="value" :img-width="200" :img-height="[100, 1000]"&gt;&lt;/sp-upload&gt;</pre>
          <p>设置属性 <span class="text-red">img-width、img-height</span>，可限制上传图片的宽度、高度，值可以固定数字或者数组范围。</p>
        </Card>
        <div class="page-main-content-subhead">Props</div>
        <Table :columns="propsColumns" :data="propsData" border />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        params: {
          'value': {
            tips: '当前上传文件数据，可以使用 v-model 双向绑定数据',
            type: 'String, Array',
            default: ''
          },
          'action': {
            tips: '上传的地址',
            type: 'String',
            default: ''
          },
          'headers': {
            tips: '设置上传的请求头部',
            type: 'Object',
            default: '{}'
          },
          'data': {
            tips: '上传时附带的额外参数',
            type: 'Object',
            default: ''
          },
          'name': {
            tips: '上传的文件字段名',
            type: 'String',
            default: 'file'
          },
          'with-credentials': {
            tips: '是否支持发送 cookie 凭证信息',
            type: 'Boolean',
            default: 'false'
          },
          'multiple': {
            tips: '是否支持多选文件',
            type: 'Boolean',
            default: 'false'
          },
          'paste': {
            tips: '是否支持粘贴上传文件',
            type: 'Boolean',
            default: 'false'
          },
          'remove': {
            tips: '是否支持删除文件',
            type: 'Boolean',
            default: 'true'
          },
          'move': {
            tips: '是否支持左右移动文件',
            type: 'Boolean',
            default: 'false'
          },
          'disabled': {
            tips: '是否禁用',
            type: 'Boolean',
            default: 'false'
          },
          'video': {
            tips: '是否视频',
            type: 'Boolean',
            default: 'false'
          },
          'show-upload-list': {
            tips: '是否显示已上传文件列表',
            type: 'Boolean',
            default: 'true'
          },
          'type': {
            tips: '上传控件的类型，可选值为 select（点击选择），drag（支持拖拽）',
            type: 'String',
            default: 'select'
          },
          'accept': {
            tips: '接受上传的文件类型',
            type: 'String',
            default: ''
          },
          'format': {
            tips: '支持的文件类型，与 accept 不同的是，format 是识别文件的后缀名，accept 为 input 标签原生的 accept 属性，会在选择文件时过滤，可以两者结合使用',
            type: 'Array',
            default: '["jpg", "jpeg", "png"]'
          },
          'max-size': {
            tips: '文件大小限制，单位 kb，默认不超过5M',
            type: 'Number',
            default: '5120'
          },
          'img-width': {
            tips: '图片宽度限制，值为数字时宽度要等于该值，值为数组时宽度要在该范围内',
            type: 'Number, Array',
            default: ''
          },
          'img-height': {
            tips: '图片高度限制，值为数字时高度要等于该值，值为数组时高度要在该范围内',
            type: 'Number, Array',
            default: ''
          },
          'before-upload': {
            tips: '上传文件之前的钩子，参数为上传的文件，若返回 false 或者 Promise 则停止上传',
            type: 'Function',
            default: ''
          },
          'on-progress': {
            tips: '文件上传时的钩子，返回字段为 event, file, fileList',
            type: 'Function',
            default: ''
          },
          'on-success': {
            tips: '文件上传成功时的钩子，返回字段为 response, file, fileList',
            type: 'Function',
            default: ''
          },
          'on-error': {
            tips: '文件上传失败时的钩子，返回字段为 error, response, file',
            type: 'Function',
            default: ''
          },
          'on-remove': {
            tips: '文件移除时的钩子，返回字段为 file, fileList',
            type: 'Function',
            default: ''
          },
          'on-move': {
            tips: '文件左右移动时的钩子，返回字段为 file, fileList',
            type: 'Function',
            default: ''
          },
          'on-alert': {
            tips: '显示提示文案时的钩子，返回字段为 message',
            type: 'Function',
            default: ''
          }
        },
        propsColumns: [
          {
            title: '属性',
            key: 'name',
            width: 140
          },
          {
            title: '说明',
            key: 'tips'
          },
          {
            title: '类型',
            key: 'type',
            width: 140
          },
          {
            title: '默认值',
            key: 'default',
            width: 160
          }
        ],
        value: '',
        valueList: []
      };
    },
    computed: {
      propsData() {
        let val = [];
        let data = this.params;
        Object.keys(data).forEach(key => {
          let item = Object.assign({}, data[key], {
            name: key
          });
          item.default = item.default || '-';
          val.push(item);
        });
        return val;
      }
    },
    created() {
      this.getData();
    },
    methods: {
      /**
       * 获取数据
       */
      getData() {
        this.$axios({
          url: this.$api.test.queryTestList,
          mock: true
        }).then(data => {
          data.list.forEach((item, index) => {
            if (index === 1) {
              this.value = item.pic;
            }
            this.valueList.push({
              name: item.name,
              url: item.pic
            });
          });
        });
      }
    }
  };
</script>